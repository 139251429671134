import { useEffect, useState } from 'react';
import apiClient from '../../services/apiClientService';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useTenant } from '../../contexts/TenantContext';

const useGetSupplies = () => {
  const { getTenant } = useTenant();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { handleError } = useErrorHandler();
  
  const getSupplies = async () => {
    setLoading(true);
    
    try {
      const tenant = await getTenant();
      const url = `/v1/public/tenants/${tenant.tenantId}/supplies`;
      const response = await apiClient.get(url);
      setData(response.data);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSupplies();  
  }, []);

  return { data, loading };
};

export default useGetSupplies;
