import { Stack } from '@mui/material';
import { useState } from 'react';
import CustomButton from '../../../../components/CustomButton';
import CustomModal from './CustomModal';
import dayjs from 'dayjs';
import { getFormattedDate, getTimeFromDate } from '../../../../utils/dateUtils';
import CustomDateTimePicker from '../../../../components/CustomDateTimePicker';
import useBlockPeriod from '../../api/useBlockPeriod';
import { useSnackbar } from '../../../../contexts/SnackBarContext';

const BlockTimeModal = (props) => {
    const { show, handleClose, reloadSignal = () => null } = props;
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const { blockPeriod } = useBlockPeriod();
    const showSnackbar = useSnackbar();

    const validateValues = () => {
      return startDate.isBefore(endDate);
    };

    const handleBlockAppointment = async () => {
      if (!validateValues()) {
        console.log("Invalid values");
        return;
      }

      const success = await blockPeriod({
        startDate: getFormattedDate(startDate), endDate: getFormattedDate(endDate),
        startTime: getTimeFromDate(startDate), endTime: getTimeFromDate(endDate)
      });

      if (success) {
        showSnackbar("Szabadnap rögzítve", "success");
        reloadSignal();
        handleClose();
      }
    };

    return (
      <CustomModal
        open={show}
        onClose={handleClose}
        title="Inaktív időszak beállitása"
      >
        <CustomDateTimePicker label="Mettöl" date={startDate} setDate={setStartDate} />
        <CustomDateTimePicker label="Meddig" date={endDate} setDate={setEndDate} />
        <CustomButton color="primary" text="Rögzítés" onClick={handleBlockAppointment} />
      </CustomModal>
    );
};

export default BlockTimeModal;