import { createTheme, ThemeProvider } from '@mui/material/styles';
import { huHU as datePickerHu } from '@mui/x-date-pickers/locales';
import { huHU as coreHu } from '@mui/material/locale';
import { useTenant } from '../contexts/TenantContext';
import { useEffect } from 'react';

const DynamicThemeProvider = ({ children }) => {
  const { tenant, displayConfig } = useTenant();
  const theme = createTheme(
    {
      background: displayConfig?.background || "#fff",
      palette: {
        primary: {
          main: tenant?.displayConfig?.primaryColor || "#000",
        },
        dark: {
          main: "#000",
        },
      },
    },
    datePickerHu,
    coreHu
  );

  const changeFavicon = (url) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = url;

    if (!document.head.contains(link)) {
      document.head.appendChild(link);
    }
  };

  useEffect(() => {
    changeFavicon(`/${tenant?.tenantId}/favicon.ico`)
    document.title = tenant?.name || "DABS";
  }, [tenant]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default DynamicThemeProvider;
