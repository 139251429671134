import { createContext, useContext, useState, useEffect } from 'react';
import { storage } from '../services/storageService'; 
import { StorageKeys } from '../services/constants/StorageKeys'; 
import useGetTenant from '../api/useGetTenant';
import { extractTenantId } from '../utils/tenantUtils';

const TenantContext = createContext();

export const useTenant = () => {
  return useContext(TenantContext);
};

export const TenantProvider = ({ children }) => {
  const [tenant, setTenant] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getTenant: fetchTenant, error } = useGetTenant();

  const getTenant = async () => {
    const storedTenant = storage.get(StorageKeys.TENANT);
    if (storedTenant) {
      setTenant(storedTenant);
      return storedTenant; 
    } else {
      const tenantId = extractTenantId()
      const fetchedTenant = await fetchTenant(tenantId);
      setTenant(fetchedTenant);
      //TODO storage.set(StorageKeys.TENANT, fetchedTenant)
      console.log(fetchedTenant)
      return fetchedTenant;
    }
  }

  const initializeTenant = async () => {
    setLoading(true);
    await getTenant();
    setLoading(false)
  }

  useEffect(() => {
    initializeTenant();
  }, []);


  return (
    <TenantContext.Provider value={{ 
      tenant, 
      displayConfig: tenant?.displayConfig,
      homePage: tenant?.displayConfig?.homePage,
      legal: tenant?.displayConfig?.legal,
      getTenant,
      loading,
      error 
    }}>
      {children}
    </TenantContext.Provider>
  );
};
