import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const CustomButton = ({
  variant = 'contained',
  onClick,
  color = "primary",
  text = null,
  textTransform = 'capitalize',
  mt = 0,
  type = "button",
  width,
  fullwidth = false,
  textColor = null,
  icon = null
}) => {
  return (
    <Box display="flex" justifyContent="center" mt={mt} width={width}>
      <Button variant={variant} onClick={onClick} type={type} fullWidth={width ? true : fullwidth} color={color}>
        <Typography fontWeight="bold" textTransform={textTransform} color={textColor}>
          {icon}
          {text?.split("<br />").map((t, index) => 
            <React.Fragment key={index}>
              {t} <br />
            </React.Fragment>
          )}
        </Typography>
      </Button>
    </Box>
  );
};

export default CustomButton;
