import React from 'react';
import { Box, Container, Stack, Typography, Link, IconButton, Divider, useMediaQuery } from "@mui/material";
import { Facebook, Instagram, LocationOn, Email, Phone } from '@mui/icons-material';
import { NavLink } from "react-router-dom";
import { Routes } from '../routes/RouteConstants';
import { useTenant } from '../contexts/TenantContext';

const iconButtonStyles = {
  backgroundColor: 'black',
  color: 'white',
  '&:hover': {
    backgroundColor: "rgb(60, 60, 60)",
  },
};

export default function Footer() {
  const { tenant, displayConfig } = useTenant();
  const isLg = useMediaQuery(theme => theme.breakpoints.up('lg'));

  return (
    <Box component="footer" sx={{ borderTop: '1px solid black', background: displayConfig?.footerBackground }}>
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap" overflow="hidden">
          <Stack
            id="contacts" 
            direction={{ lg: "row" }}
            spacing={{ xs: 2, md: 3 }} 
            divider={isLg ? <Divider orientation="vertical" flexItem /> : <Divider orientation="horizontal" flexItem/>}
            alignItems={{ lg: "center" }}
            py={3}
          >
            {displayConfig?.address && 
              <Box>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <LocationOn style={{ color: displayConfig?.footerIconColor }} />
                  <Typography color="black" fontWeight="bold">Cím</Typography>
                </Stack>

                <Typography color="black" fontWeight="medium" noWrap>
                  {displayConfig.address}
                </Typography>
              </Box>
            }
            
            {displayConfig?.email && 
              <Box>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Email style={{ color: displayConfig?.footerIconColor }} />
                  <Typography color="black" fontWeight="bold">Email</Typography>
                </Stack>
                
                <Link display="block" color="#000" href="mailto:adam19980525@gmail.com" fontWeight="medium" noWrap>
                  <Typography>{displayConfig?.email}</Typography>
                </Link>
              </Box>
            }

            {displayConfig?.phoneNumber && 
              <Box>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Phone style={{ color: displayConfig?.footerIconColor }} />
                  <Typography color="#000" fontWeight="bold">Telefon</Typography>
                </Stack>
                <Link display="block" color="#000" href="tel:+36304528948" fontWeight="medium" noWrap>
                  {displayConfig.phoneNumber}
                </Link>
              </Box>
            }
            
          </Stack>

          <Stack direction="row" spacing={2} py={2}>
            { displayConfig?.instagramUrl &&
              <IconButton href={displayConfig.instagramUrl} target="_blank" sx={iconButtonStyles}>
                <Instagram />
              </IconButton>
            }

            { displayConfig?.facebookUrl &&
              <IconButton href={displayConfig.facebookUrl} target="_blank" sx={iconButtonStyles}>
                <Facebook />
              </IconButton>
            }
          </Stack>
        </Stack>
      </Container>

      <Stack direction="row" alignItems="center" justifyContent="center" spacing={3} py={1} sx={{ bgcolor: "black" }}>
        <NavLink to={Routes.PRIVAY_POLICY} style={{ textDecoration: 'none', color: "white" }}>Adatkezelési Nyilatkozatot</NavLink>
        <NavLink to={Routes.TERMS_AND_CONDITIONS} style={{ textDecoration: 'none', color: "white" }}>ÁSZF</NavLink>
      </Stack>

      <Stack alignItems="center" py={1} color="#fff" sx={{ bgcolor: "black" }}>
        <Typography variant="body2">
          © {new Date().getFullYear() + " " + tenant?.name}. Minden jog fenntartva.
        </Typography>
      </Stack>
    </Box>
  )
}
