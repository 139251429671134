import { 
  Typography,
  Button, 
} from "@mui/material";
import Page from "../../../components/Page";
import API_BASE_URL from '../../../configs/config';
import isEmbeddedBrowser from "../../../utils/embeddedBrowserChecker";
import React, { useState, useEffect } from 'react';
import Article from "../../../components/Article";
import { useOutletContext } from "react-router-dom";
import useInitiateSocialLogin from "../useInitiateSocialLogin";
import Loading from "../../../components/Loading";

const LoginLayout = () => {
  const { initiateSocialLogin, loading } = useInitiateSocialLogin();
  const [embeddedBrowser, setEmbeddedBrowser] = useState(false);
  const { forceHeaderReRender } = useOutletContext();

  useEffect(() => {
    setEmbeddedBrowser(isEmbeddedBrowser());
    forceHeaderReRender();
  }, []);

  const handleSocialLogin = async () => {  
    window.location = await initiateSocialLogin(); 
  };

  return (
    <Page>
      <Article xsHorizontalPadding={2} title="Bejelentkezés" titleColor="primary">
        <Typography variant="body1" align="center" color="text.primary" mb={5} noWrap fontSize={{ xs: "14px", sm: "16px" }}>
          A foglalás véglegesítéséhez be kell jelentkezned!
        </Typography>

        {loading && <Loading />}

        {!embeddedBrowser && !loading &&
          <Button
            variant="outlined"
            color="primary"
            startIcon={<img src="/google-icon.svg" alt="Google Icon" style={{ width: '18px', height: '18px' }} />} 
            onClick={() => handleSocialLogin()}
          >
            <Typography textTransform="none" color="black" noWrap>Bejelentkezés Google-lal</Typography>
          </Button> 
        }

        {embeddedBrowser && (
          <>
            <Typography variant="h6" color="error" mt={2} textAlign="left" fontWeight="bold">
              Használd az alkalmazást egy másik böngészőből!
            </Typography>
            <Typography variant="body2" color="error" mt={2} textAlign="left" fontWeight="bold">
              Ha messenger, instagram vagy egyéb másik alkalmazásból nyitod meg a weboldalt akkor a bejelentkezés nem lehetséges. Másold át a linket egy böngészőbe!
            </Typography>
          </>
        )}
      </Article>
    </Page>  
  );
};

export default LoginLayout;
