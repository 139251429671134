import React from 'react';
import Page from '../components/Page';
import Article from '../components/Article';
import { Typography} from '@mui/material';
import { useTenant } from '../contexts/TenantContext';

const TermsAndConditionsLayout = () => {
const { legal } = useTenant();

  return (
    <Page>
      <Article title="Általános Szerződési Feltételek" titleVariant="h6" alignItems="start">
        <Typography variant="h5" my={3}>
          1. Bevezetés
        </Typography>
        <Typography paragraph>
          Jelen dokumentum (a továbbiakban: "Feltételek") meghatározza: {legal?.name} (a továbbiakban: "Szolgáltató") 
          tulajdonában lévő webalkalmazás használatának feltételeit. A webalkalmazás használatával Ön elfogadja a jelen 
          Feltételeket, és vállalja, hogy betartja az itt meghatározott szabályokat.
        </Typography>

        <Typography variant="h5" my={3}>
          2. Tulajdonjog és Hatály
        </Typography>
        <Typography paragraph>
          A webalkalmazás a Szolgáltató tulajdonát képezi. A webalkalmazás célja az időpontfoglalások, ügyfélkapcsolatok és 
          egyéb szolgáltatások biztosítása.
        </Typography>

        <Typography variant="h5" my={3}>
          3. Szolgáltatás Használata
        </Typography>
        <Typography paragraph>
          A webalkalmazáson elérhető szolgáltatások használata során Ön köteles betartani a jelen Feltételeket. A 
          szolgáltatások kizárólag jogszerű célokra használhatók, és tilos a szolgáltatások bármilyen visszaélésszerű 
          vagy jogellenes használata. A Szolgáltató nem felelős az Ön által elkövetett jogellenes tevékenységekért.
        </Typography>

        <Typography variant="h5" my={3}>
          4. Felhasználói Fiókkezelés
        </Typography>
        <Typography paragraph>
          Felhasználói fiókját a webalkalmazáson keresztül kezelheti. A fiók létrehozásakor és kezelésekor Ön köteles 
          valós, pontos, naprakész és teljes körű adatokat megadni. A fiók biztonságának megőrzéséért Ön felelős, és 
          köteles minden szükséges intézkedést megtenni az illetéktelen hozzáférés megakadályozása érdekében. A 
          Szolgáltató nem vállal felelősséget a felhasználói fiókok jogosulatlan használatáért.
        </Typography>

        <Typography variant="h5" my={3}>
          5. Adatkezelés és Megosztás
        </Typography>
        <Typography paragraph>
          Az Ön személyes adatait a webalkalmazáson tároljuk és kezeljük a szolgáltatás nyújtása érdekében. 
          Az adatokat harmadik féllel való megosztására nem kerül sor. Az 
          adatkezeléssel kapcsolatos további információkért kérjük, tekintse meg az Adatvédelmi Nyilatkozatot.
        </Typography>

        <Typography variant="h5" my={3}>
          6. Felelősség Korlátozása
        </Typography>
        <Typography paragraph>
          A Szolgáltató kizárja a felelősségét a webalkalmazás használatából eredő bármilyen közvetlen, közvetett, 
          járulékos, különleges vagy következményes kárért vagy veszteségért, beleértve, de nem kizárólagosan, az 
          adatvesztést, a nyereségkiesést vagy az üzleti lehetőségek elvesztését. A felhasználó tudomásul veszi, hogy 
          a szolgáltatásokat saját felelősségére használja.
        </Typography>

        <Typography variant="h5" my={3}>
          7. Feltételek Módosítása
        </Typography>
        <Typography paragraph>
          A Szolgáltató fenntartja a jogot a jelen Feltételek időről időre történő módosítására. A módosításokat a 
          webalkalmazásban tesszük közzé, és azok a közzétételt követően azonnal hatályba lépnek. Javasoljuk, hogy 
          rendszeresen ellenőrizze a Feltételeket.
        </Typography>

        <Typography paragraph>
          A jelen Feltételek a magyar jog hatálya alá tartoznak. Bármilyen, a Feltételekkel kapcsolatban felmerülő 
          jogvita esetén a magyar bíróságok kizárólagos illetékességgel rendelkeznek.
        </Typography>
      </Article>
    </Page>
  );
};

export default TermsAndConditionsLayout;
