import { Box, Container, Paper, useTheme, Grid, Typography, Stack, Divider, useMediaQuery } from "@mui/material";
import OpeningHoursItem from "./OpeningHoursItem";
import { Routes } from "../../routes/RouteConstants";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useTenant } from "../../contexts/TenantContext";

export default function HomeLayout() {
  const { tenant, displayConfig, homePage } = useTenant();
  const tenantId = tenant?.tenantId;
  const openingHours = homePage?.openingHours

  const theme = useTheme();
  const downXl = useMediaQuery(theme.breakpoints.down('xl'));
  const navigate = useNavigate();

  return (
    <>
      <Container maxWidth="xl" disableGutters={downXl}>
        <Grid container overflow="hidden">
          <Grid item xs={12} md={9} lg={10} display="flex" justifyContent="center">
            <Box
              component="img"
              src={`/${tenantId}/hero_image.jpg`}
              sx={{
                width: "100%",
                maxHeight: { lg: "90vh", xl: "70vh" },
                objectFit: 'cover'
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2} py={2} bgcolor={{ xs: "#323232", md: "transparent" }} pr={{ md: 1 }} display="flex" alignItems="center" justifyContent={{ xs: "center", md: "end" }}>
            <Paper elevation={3} sx={{ p: 5,  minWidth: { xs: "82.5%", sm: "90%", md: "40vw", lg: "30vw", xl: "15vw" }}}>
              <Typography
                variant="h2"
                fontSize={24}
                align="center"
                color="grey"
                fontWeight="bold"
                mb={3}
              >
                Üdvözöllek a 
                <Typography variant="h1" fontSize={32} color={displayConfig?.primaryColor} style={{ whiteSpace: 'nowrap'}}>
                  {tenant?.name}
                </Typography>
                oldalán!
              </Typography>

               <CustomButton text="Foglalás" onClick={() => navigate(Routes.BOOKING)} />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="xl">
        <Paper id="openingHours" elevation={3} sx={{ my: { xs: 3, lg: 4, xl: 5 }, p: 3, display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant="h4" color="black" fontWeight="bold" mb={3}>
            Nyitvatartás
          </Typography>

          <Stack width={{ xs: "85%", sm: "70%", md: "35%" }} divider={<Divider orientation="horizontal" flexItem/>}>
            <OpeningHoursItem day={"Hétfő"} hours={openingHours?.monday} />
            <OpeningHoursItem day={"Kedd"} hours={openingHours?.tuesday} />
            <OpeningHoursItem day={"Szerda"} hours={openingHours?.wednesday} />
            <OpeningHoursItem day={"Csütörtök"} hours={openingHours?.thursday} />
            <OpeningHoursItem day={"Péntek"} hours={openingHours?.friday} />
            <OpeningHoursItem day={"Szombat"} hours={openingHours?.saturday} />
            <OpeningHoursItem day={"Vasárnap"} hours={openingHours?.sunday} />
          </Stack >
        </Paper>

        <Box component="iframe"
          title="Google Maps"
          src={homePage?.googleMapsUrl}
          width="100%"
          height="50vh"
          loading="lazy"
          border={0}
          referrerpolicy="no-referrer-when-downgrade"
          mb={3}
        ></Box>
      </Container>
    </>
  )
}
