import { Box, Card, Stack, Typography } from '@mui/material';
import CustomButton from '../../../components/CustomButton';

export const DailyScheduleCard = ({ dailySchedule, onBookedSlotClick, onFreeSlotClick }) => {
  const renderTimeSlot = (date, time) => {
    if (!time || !date) return <></>;

    if (time.available !== true) {
      return (
        <CustomButton
          key={`${date}-${time.startTime}`}
          variant="outlined"
          onClick={() => onBookedSlotClick(time.id)}
          fullwidth={true}
          text={`${time?.guest} <br /> ${time?.startTime} - ${time?.endTime}`}
        />
      );
    }

    return (
      <CustomButton
        key={`${date}-${time.startTime}`}
        variant="outlined"
        color="dark"
        onClick={() => onFreeSlotClick(date, time)}
        fullwidth={true}
        text={time.startTime}
      />
    );
  };

  return (
    <Card>
      <Stack direction={{ xs: "column", xl: "row" }} justifyContent="space-around" alignItems="center" p={1} bgcolor="black">
        <Typography variant="h6" textTransform="capitalize" color="white" noWrap>
          {dailySchedule.weekday}
        </Typography>

        <Typography variant="body1" color="grey.200" noWrap fontSize={{ xs: 12, sm: 14, md: 16 }}>
          {dailySchedule.formattedDate}
        </Typography>
      </Stack>

      <Box>
        <Stack p={1} spacing={1}>
          {dailySchedule.timeSlots.map(time => renderTimeSlot(dailySchedule.date, time))}
        </Stack>
      </Box>
    </Card>
  );
};