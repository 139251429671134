import { Box, Grid, MenuItem, Stack, TextField, IconButton  } from '@mui/material';
import React, { useState } from 'react';
import Page from '../../components/Page';
import ManualBookingModal from './components/modals/ManualBookingModal';
import BlockTimeModal from './components/modals/BlockTimeModal';
import useGetSupplies from '../supply/useGetSupplies';
import CustomButton from '../../components/CustomButton';
import BookingDetailsModal from './components/modals/BookingDetailsModal';
import Loading from '../../components/Loading';
import useDailySchedules from './hooks/useDailySchedules';
import { DailyScheduleCard } from './components/DailyScheduleCard';
import CloseIcon from '@mui/icons-material/Close';

const DashboardPage = () => {
  const { data: suppliesData } = useGetSupplies();
  const [supplyId, setsupplyId] = useState("");
  const { fetchedDailySchedules, loading, loadMoreSchedules,
      reloadSchedules, reloadSchedulesWithoutSupplyId } = useDailySchedules(supplyId);
  const [modalState, setModalState] = useState({
      showAppointmentModal: false,
      showBlockTimeModal: false,
      showBookingModal: false,
      modalData: null,
      bookingId: ""
  });

  const [isBookingActive, setIsBookingActive] = useState(false);

  const openBookingModal = (date, time) => {
    setModalState({
      ...modalState,
      showAppointmentModal: true,
      modalData: { date, time, supply: suppliesData.find(supply => supply.id === supplyId) }
    });
  };

  const openBookingDetailsModal = (bookingId) => setModalState({ ...modalState, showBookingModal: true, bookingId });

  const openBlockTimeModal = () => setModalState({ ...modalState, showBlockTimeModal: true });

  const handleCloseBookingSection = () => {
    setsupplyId("");
    setIsBookingActive(false);
    reloadSchedulesWithoutSupplyId();
  };

  return (
    <Page>
      <Stack spacing={2}>
        <Box>
          <CustomButton 
            color="primary" 
            text="Foglalás" 
            onClick={() => setIsBookingActive(true)} 
            width="300px" 
          />

          {isBookingActive && <Stack direction="row" width="300px" >
            <TextField 
              select 
              value={supplyId}
              label="Szolgáltatás"
              variant="outlined" 
              margin="normal" 
              fullWidth 
              onChange={(event) => setsupplyId(event.target.value)}
            >
              {suppliesData?.map(supply => 
                <MenuItem key={supply.id} value={supply.id}>
                  {supply.name}
                </MenuItem>)}
            </TextField>
            <IconButton onClick={handleCloseBookingSection} sx={{ ml: 1 }}>
                <CloseIcon />
            </IconButton>
          </Stack>}
        </Box>

        <CustomButton 
          color="primary"
          text="Speciális foglalás" 
          onClick={openBlockTimeModal}
          width="300px" 
        />

        <CustomButton 
          color="primary"
          text="Inaktív időszak beállítás"
          onClick={openBlockTimeModal} 
          width="300px" 
        />
      </Stack>

      <Box my={5} width="100%">
        {loading ? (
          <Box height="50vh" display="flex" flexDirection="column" justifyContent="center">
            <Loading />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {fetchedDailySchedules?.map(dailySchedule => (
              <Grid key={dailySchedule.date} item xs={6} sm={6} md={4} lg={2}>
                <DailyScheduleCard 
                  key={dailySchedule.date}
                  dailySchedule={dailySchedule}
                  onBookedSlotClick={openBookingDetailsModal}
                  onFreeSlotClick={openBookingModal}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <CustomButton variant="outlined" color="primary" text="Mutass többet" onClick={loadMoreSchedules} width="300px" />
      
      <ManualBookingModal 
        show={modalState.showAppointmentModal}
        modalData={modalState.modalData} 
        handleClose={() => setModalState({ ...modalState, showAppointmentModal: false })}
        reloadSignal={reloadSchedules} 
      />
      
      <BlockTimeModal 
        show={modalState.showBlockTimeModal}
        handleClose={() => setModalState({ ...modalState, showBlockTimeModal: false })}
        reloadSignal={reloadSchedules} 
      />

      <BookingDetailsModal 
        show={modalState.showBookingModal}
        handleClose={() => setModalState({ ...modalState, showBookingModal: false })}
        bookingId={modalState.bookingId}
        reloadSignal={reloadSchedules} 
      />
    </Page>
  );
};

export default DashboardPage;
