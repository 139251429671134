import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import useGetDailySchedules from '../../../api/useGetDailySchedules';

const useDailySchedules = (supplyId) => {
  const [fetchedDailySchedules, setFetchedDailySchedules] = useState([]);
  const { data: newDailySchedules, loading, getDailySchedules } = useGetDailySchedules(true);

  const reloadSchedules = () => {
    setFetchedDailySchedules([]);
    getDailySchedules(dayjs(), true, supplyId);
  };

  const reloadSchedulesWithoutSupplyId = () => {
    setFetchedDailySchedules([]);
    getDailySchedules(dayjs(), true, "");
  };

  useEffect(() => {
    if (supplyId) {
      reloadSchedules();
    }
  }, [supplyId]);

  useEffect(() => {
    // Prevents unnecessary re-renders
    if (newDailySchedules.length === 0) return;

    // Prevents adding the same daily schedule multiple times
    const firstNewDate = newDailySchedules[0]?.date;
    const fetchedDates = fetchedDailySchedules.map(ds => ds.date);
    if (fetchedDates.includes(firstNewDate)) return; 

    setFetchedDailySchedules(prevData => [...prevData, ...newDailySchedules]);
  }, [newDailySchedules]);

  const loadMoreSchedules = () => {
    const nextDay = dayjs(newDailySchedules[newDailySchedules.length - 1].date).add(1, 'day');
    getDailySchedules(nextDay, true, supplyId);
  };

  return {
    fetchedDailySchedules,
    loading,
    loadMoreSchedules,
    reloadSchedules,
    reloadSchedulesWithoutSupplyId,
  };
};

export default useDailySchedules;
