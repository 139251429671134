import { Navigate } from "react-router-dom";
import { StorageKeys } from "../services/constants/StorageKeys";
import { storage } from "../services/storageService";
import { Routes } from "./RouteConstants";
import { useTenant } from '../contexts/TenantContext';

export const ProtectedHomeRoute = ({ children }) => {
  const { homePage } = useTenant();
  if (!homePage) return null; 

  return homePage ? children : <Navigate to={Routes.SUPPLY} />;
};

export const ProtectedBookingRoute = ({ children }) => {
  const selectedSupply = storage.get(StorageKeys.SELECTED_SUPPLY);
  
  return selectedSupply ? children : <Navigate to={Routes.SUPPLY} />;
};

export const ProtectedPrePreserveRoute = ({ children }) => {
  const selectedSupply = storage.get(StorageKeys.SELECTED_SUPPLY);
  const selectedAppointment = storage.get(StorageKeys.SELECTED_APPOINTMENT);
  const isUserAuthenticated = storage.get(StorageKeys.IS_USER_AUTHENTICATED);

  if (!selectedSupply) return <Navigate to={Routes.SUPPLY} />;

  if (!selectedAppointment) return <Navigate to={Routes.BOOKING} />;

  if (!isUserAuthenticated) {
    storage.set(StorageKeys.TARGET_ROUTE_BEFORE_REDIRECT, Routes.PRE_RESERVE);
    return <Navigate to={Routes.LOGIN} />;
  }

  return children;
};

export const ProtectedConfirmBookingRoute = ({ children }) => {
  const selectedSupply = storage.get(StorageKeys.SELECTED_SUPPLY);
  const selectedAppointment = storage.get(StorageKeys.SELECTED_APPOINTMENT);
  const isUserAuthenticated = storage.get(StorageKeys.IS_USER_AUTHENTICATED);
  const preReservedBookingId = storage.get(StorageKeys.PRE_RESERVED_BOOKING_ID);
  
  if (!selectedSupply) return <Navigate to={Routes.SUPPLY} />;

  if (!selectedAppointment) return <Navigate to={Routes.BOOKING} />;

  if (!isUserAuthenticated) {
    storage.set(StorageKeys.TARGET_ROUTE_BEFORE_REDIRECT, Routes.CONFIRM_BOOKING);
    return <Navigate to={Routes.LOGIN} />;

  }
  if (!preReservedBookingId) return <Navigate to={Routes.PRE_RESERVE} />;

  return children;
};

export const ProtectedProfileRoute = ({ children }) => {
  const isUserAuthenticated = storage.get(StorageKeys.IS_USER_AUTHENTICATED);

  if (!isUserAuthenticated) {
    storage.set(StorageKeys.TARGET_ROUTE_BEFORE_REDIRECT, Routes.PROFILE);
    return <Navigate to={Routes.LOGIN} />;
  }

  return children;
};

export const ProtectedDashboardRoute = ({ children }) => {
  const isUserAuthenticated = storage.get(StorageKeys.IS_USER_AUTHENTICATED);

  if (!isUserAuthenticated) {
    storage.set(StorageKeys.TARGET_ROUTE_BEFORE_REDIRECT, Routes.DASHBOARD);
    return <Navigate to={Routes.LOGIN} />;
  }

  return children;
};

